import { getCountryCode } from "@theme/views/utils";

const digits = (countryCode) =>
  countryCode === '971' || countryCode === '966' ? 9 : 8;

export const isValid = (phoneNumber: string) => {
  if (!phoneNumber) return false;

  const countryCode = phoneNumber.match(
    new RegExp(/^(\+?\d{1,3}|\d{1,4})/gm)
  )?.[0];

  if (!countryCode) return false;

  const input = phoneNumber.replace(countryCode, '');

  if (input.length !== digits(countryCode)) return false;

  const { regex } = getRegex(countryCode);

  if (!new RegExp(regex ? `^${regex}` : /^\d+$/).test(input)) return false;

  return true;
};

const getRegex = (countryCode: string) => {
  let regex = null;
  let example = null;

  switch (countryCode) {
    case '971':
      regex = 5;
      example = '565050000';
      break;
    case '966':
      regex = 5;
      example = '515050000';
      break;
    case '968':
      regex = 7;
      example = '77050000';
      break;
    case '974':
      regex = '(3|4|5|6|7)';
      example = '77050000';
      break;
    case '973':
      regex = '(3|6)';
      example = '34050000';
      break;
    case '965':
      example = '55050000';
      regex = null;
      break;
    default:
      example = '565050000';
      regex = null;
  }

  return { regex, example };
};

export const phoneValidate = (t, message?: any, countryCode?: string, schema?:any, locale?:string) => {
  let minNumber;
  if(countryCode) {
    minNumber = digits(countryCode);
  } else {
    minNumber = digits(getCountryCode(locale));
  }

  const { regex, example } = getRegex(countryCode);

  const replaceMessage = regex
    ? message?.error_with
        .replace('*', minNumber.toString())
        .replace('-', regex)
        .replaceAll(/\(|\)/g, '')
        .replaceAll('|', ', ')
        .replace('phoneNumber', example)
    : message?.error_without
        .replace('*', minNumber.toString())
        .replace('phoneNumber', example);

  return schema
    .min(minNumber, replaceMessage)
    .max(minNumber, replaceMessage)
    .matches(regex ? `^${regex}` : /^\d+$/, replaceMessage);
};

export const handleChangePlaceholder = (
  countryCode: string,
  handleChangePlaceholderFormat?: (placeholder: {
    placeholder: string;
    format: string;
  }) => void
) => {
  let placeholder;

  switch (countryCode) {
    case '974':
    case '968':
      placeholder = {
        placeholder: '77XXXXXX',
        format: '99999999'
      };
      break;
    case '971':
      placeholder = {
        placeholder: '56XXXXXXX',
        format: '999999999'
      };
      break;
    case '966':
      placeholder = {
        placeholder: '51XXXXXXX',
        format: '999999999'
      };
      break;
    case '965':
      placeholder = {
        placeholder: '55XXXXXX',
        format: '99999999'
      };
      break;
    case '973':
      placeholder = {
        placeholder: '36XXXXXX',
        format: '99999999'
      };
      break;
    default:
      placeholder = {
        placeholder: '56XXXXXXX',
        format: '99999999'
      };
  }

  if (handleChangePlaceholderFormat) {
    handleChangePlaceholderFormat(placeholder);
  }

  return placeholder;
};
